import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { Suspense } from "react";
import "./App.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RouteLayout({ routes }) {
  const element = useRoutes(routes);
  return element;
}

function App() {
  return (
    <>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="loaderBox">
            <span />
          </div>
        }
      >
        <RouteLayout routes={routes()} />
      </Suspense>
    </>
  );
}

export default App;
