import { baseRoutes } from "../../helpers/baseRoutes";

const adminRouteMap = {
  LOGIN: { path: `${baseRoutes.adminBaseRoutes}` },
  DASHBOARD: { path: `${baseRoutes.adminBaseRoutes}/dashboard` },
  ADMINFORGOT: { path: `${baseRoutes.adminBaseRoutes}/forgot` },
  ADMINRESET: { path: `${baseRoutes.adminBaseRoutes}/reset-password` },
  ADMINPROFILE: { path: `${baseRoutes.adminBaseRoutes}/my-profile` },
  ADMINUSER: { path: `${baseRoutes.adminBaseRoutes}/user` },
  ADMINUSERDETAILS: { path: `${baseRoutes.adminBaseRoutes}/user-details` },
  ADMINREVIEW: { path: `${baseRoutes.adminBaseRoutes}/testimonials` },
  ADMINCMS: { path: `${baseRoutes.adminBaseRoutes}/cms` },
  ADMINFAQ: { path: `${baseRoutes.adminBaseRoutes}/faq` },
  ADMINSUPPORT: { path: `${baseRoutes.adminBaseRoutes}/support` },
  ADMINFUNDING: { path: `${baseRoutes.adminBaseRoutes}/check-eligiblity` },
  ADMINBLOGS: { path: `${baseRoutes.adminBaseRoutes}/blogs` },
};
export default adminRouteMap;
