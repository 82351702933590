const config = {
  IMAGE_USER: process.env.REACT_APP_IMAGE_PATH,
  IMAGE_ADMIN: process.env.REACT_APP_ADMIN_IMAGE_PATH,
  IMAGE_COMMON: process.env.REACT_APP_COMMON_IMAGE_PATH,
  NAME_KEY: process.env.REACT_APP_NAME_KEY,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
};

export default config;
