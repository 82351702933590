import React from "react";

export const AdminLogin = React.lazy(() => import("./AdminLogin/index.page"));
export const AdminDashboard = React.lazy(() =>
  import("./AdminDashboard/index.page")
);
export const AdminForgot = React.lazy(() => import("./AdminForgot/index.page"));
export const AdminReset = React.lazy(() => import("./AdminReset/index.page"));
export const AdminProfile = React.lazy(() =>
  import("./AdminProfile/index.page")
);
export const AdminUser = React.lazy(() => import("./AdminUser/index.page"));
export const AdminUserDetails = React.lazy(() =>
  import("./AdminUserDetails/index.page")
);
export const AdminReview = React.lazy(() => import("./AdminReview/index.page"));
export const AdminCms = React.lazy(() => import("./AdminCms/index.page"));
export const AdminFaq = React.lazy(() => import("./AdminFaq/index.page"));
export const AdminSupport = React.lazy(() =>
  import("./AdminSupport/index.page")
);
export const AdminFunding = React.lazy(() =>
  import("./AdminFunding/index.page")
);

export const AdminBlog = React.lazy(() => import("./AdminBlog/index.page"));
