import { UserLogin, UserForgot, UserSignup, UserReset } from "../../pages/User";
import userRouteMap from "./userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.USERLOGIN.path,
      name: "User Login",
      element: <UserLogin />,
      private: false,
      common: false,
    },
    {
      path: userRouteMap.USERSIGNUP.path,
      name: "User Signup",
      element: <UserSignup />,
      private: false,
      common: false,
    },
    {
      path: userRouteMap.USERFORGOT.path,
      name: "User Forgot",
      element: <UserForgot />,
      private: false,
      common: false,
    },
    {
      path: `${userRouteMap.USERRESET.path}/:token`,
      name: "User Reset",
      element: <UserReset />,
      private: false,
      common: false,
    },
    {
      path: userRouteMap.MERCHANTLOGIN.path,
      name: "Merchant Login",
      element: <UserLogin />,
      private: false,
      common: false,
    },
    {
      path: userRouteMap.MERCHANTSIGNUP.path,
      name: "Merchant Signup",
      element: <UserSignup />,
      private: false,
      common: false,
    },
    {
      path: userRouteMap.MERCHANTFORGOT.path,
      name: "Merchant Forgot",
      element: <UserForgot />,
      private: false,
      common: false,
    },
    {
      path: `${userRouteMap.MERCHANTRESET.path}/:token`,
      name: "Merchant Reset",
      element: <UserReset />,
      private: false,
      common: false,
    },
  ];
}
